import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
var axios = require("axios");
export const config = {
  // domain: "http://localhost:1980"
  // domain: "https://api2.scottsautoja.com/api/scotts/",
  domain: "https://bla-api.scottsautoja.com/",
  name: "Scott's Auto BIMMER LIFESTYLE AUTOCARE",
  userInvoice: "https://scottsautoja.com/customer/invoice.php?id=",
};

if (process.env.NODE_ENV === "development") {
  config.domain = "http://localhost:1990/";
} else {
  config.domain = "https://bla-api.scottsautoja.com/";
}
axios.defaults.timeout = 6000;
const ax = axios.create({
  baseURL: config.domain,
  timeout: 9000,
  // headers: {'X-Custom-Header': 'foobar'}
});

export async function dataProvider(method, url, data) {
  try {
    const response = await ax({
      method: method,
      url: url,
      data: data,
      // headers: { Authorization: "Bearer " + getToken() },
    });
    console.log(response, "provider");
    return response;
   } catch (error) {
    if (error.response) {
      Swal.fire({
        // position: 'top-end',
        icon: "error",
        title: error.response.statusText,
        text: error.response.data.message,
        showConfirmButton: false,
        timer: 1800,
      });
    } else {
      Swal.fire({
        // position: 'top-end',
        icon: "warning",
        text: "Please check your internet connection.",
        showConfirmButton: false,
        timer: 1800,
      });
    }

    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   // console.log(error.response.headers);
    // }
    // if (error.response) {
    //   throw new Error(error.response?.data.message);
    // } else {
    //   throw new Error("Request timeout.");
    // }
  }
}

// export function getToken() {
//   if (checkAuth()) {
//     var token = localStorage.getItem("token");
//     token = JSON.parse(token);
//     // var decoded = jwt_decode(token);
//     // console.log(decoded);

//     return token;
//   } else {
//     return "";
//   }
// }


export async function dataProvider_(method, url, data) {

  if(!checkAuth()) return;
  try {
    const response = await ax({
      method: method,
      url: url,
      data: data,
      headers: { Authorization: "Bearer " + getToken() },
    });
    // console.log(response, "provider");
    return response;
   } catch (error) {
    if (error.response) {
      Swal.fire({
        // position: 'top-end',
        icon: "error",
        title: error.response.statusText,
        text: error.response.data.message,
        showConfirmButton: false,
        timer: 1800,
      });

      // console.error(error.response.status)

      if(error.response.status === 403){
        localStorage.removeItem("token");
        window.location.reload();
      }
    } else {
      Swal.fire({
        // position: 'top-end',
        icon: "warning",
        text: "Please check your internet connection.",
        showConfirmButton: false,
        timer: 1800,
      });
    }

    // if (error.response) {
    //   console.log(error.response.data);
    //   console.log(error.response.status);
    //   // console.log(error.response.headers);
    // }
    // if (error.response) {
    //   throw new Error(error.response?.data.message);
    // } else {
    //   throw new Error("Request timeout.");
    // }
  }
}


export const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        // console.log(name)
        //   caches.delete(name);
      }
    });
  }
  // delete browser cache and hard reload
  // window.location.reload(true);
};


export function getToken() {
  if (checkAuth()) {
    var token = localStorage.getItem("token");
    token = JSON.parse(token);
    return token;
  } else {
    return "";
  }
}

export async function doLogout() {
  localStorage.removeItem("token");
  window.location = "/login";
}


export function checkAuth() {
  var token = localStorage.getItem("token");
  if (!token) {
    return false;
  }

  token = JSON.parse(token);
  var jwt = jwt_decode(token);
  let d = parseInt(Date.now() / 1000);
  if (d < jwt.exp) {
    return true;
  } else {
    localStorage.removeItem("token");
    return false;
  }
}


export const getProfile = () => {
  var token = localStorage.getItem("token");
  if (!token) {
    return false;
  }

  token = JSON.parse(token);
  var jwt = jwt_decode(token);
  let d = parseInt(Date.now() / 1000);
  if (d < jwt.exp) {
    return jwt;
  } else {
    localStorage.removeItem("token");
    return false;
  }




}

// export const getCloudProfile = async() => {
//   try {
//     const { data } = await dataProvider_("get", "/member", login);

//     if(data.data){
//       console.log(data.data, "cloud")
//     }
//   } catch (error) {
//     console.log(error, "error-login");
//   }



// }

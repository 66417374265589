import React, { useState, useRef } from "react";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Divider,
  Chip,
  Avatar
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { dataProvider } from "../util/util";
import Swal from "sweetalert2";
import { Email } from "@material-ui/icons";
import { scroller } from "react-scroll";
import strokes from "../assets/bla-strokes.png";
import { app_config } from "../util/config";
// import Toast from "../components/Toast"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 30,
    // background: "#f3f3f3"
  },
  formRoot: {
    "& > *": {
      //   margin: theme.spacing(1),
      //   minWidth: '100%',
      //   background: 'red'
    },
  },
  flow: {
    width: "100%",
  },
  icon: {
    color: "red",
  },
  section1: {
    marginTop: 20,
  },
  subTitle: {
    color: "#0635C9",
    textAlign: "left",
    fontSize: "1.2em",
  },
}));

export default function Login() {
  const classes = useStyles();
  const [login, setLogin] = useState({});
  const pinRef = useRef(null);
  const vinRef = useRef(null)

  const scrolly = (ele) => {
    scroller.scrollTo(ele, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -90,
    });
  };
  const handleChange = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };
  const handlePinRequest = async (e) => {
    e.preventDefault();

    if (!login.vin || login.vin?.length < 2) {
      Swal.fire({
        text: "Invalid vin",
        icon: "warning",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }
    try {
      const { data } = await dataProvider("post", "/auth/requestPin", login);

      if (data.message) {
        pinRef.current.focus();
        setLogin({ ...login, email: data.data });
        Swal.fire({
          icon: data.status,
          text: data.message,
          showConfirmButton: false,
          timer: 1800,
        });
        scrolly("pin");

             
      }
    } catch (error) {
      console.log(error, "error-login");
    }
  };

  const reset = () => {
    setLogin({pin:'', vin:'', email: ''})
  }
  const handleVerifyRequest = async (e) => {
    e.preventDefault();

    if (!login.pin || login.pin?.length < 4) {
      Swal.fire({
        text: "Invalid passcode",
        icon: "warning",
        showConfirmButton: false,
        timer: 1800,
      });
      return false;
    }
    try {
      const { data } = await dataProvider("post", "/auth/verifyPin", login);

      if (data.message) {
        Swal.fire({
          // position: 'top-end',
          icon: data.status,
          text: data.message,
          showConfirmButton: false,
          timer: 1800,
        });
      }
      if(data.data){
        localStorage.setItem('token', JSON.stringify(data.data));
        window.location = "/";
      }
    } catch (error) {
      console.log(error, "error-login");
    }
  }


  return (
    <div className="main">
      <Grid
        container
        spacing={3}
        style={{ padding: "20px 10px 60px 10px", maxWidth: "100%" }}
        justifyContent="center"
      >

<Grid item xs={12} md={8} style={{textAlign:'center', paddingTop:30, marginBottom:30}}>
  <div ><img style={{width:'25%', maxWidth:200}} src={strokes} /></div>
          <Typography variant="h5" style={{ marginBottom: 10, textAlign:'center', color:'#333' }}>
            <b style={{color:'#16588E'}}>{app_config.name_short}</b> Login
          </Typography>
          <small>Welcome to {app_config.name}. Check your membership points, and stay up to date with our latest news and offers.</small>
 
        </Grid>


        <Grid item xs={12} md={8}>
          <Typography variant="h5" style={{ marginBottom: 10 }}>
            Step.1
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 10 }}>
            Enter your VIN and then request a passcode
          </Typography>
          <TextField
            label="VIN"
            name="vin"
            ref={vinRef}
            value={login?.vin}
            variant="outlined"
            className={classes.flow}
            onChange={handleChange}
            required
            // autoComplete="off"
          />
          <small style={{fontSize:'0.7em'}}>Your passcode will be sent to the mobile number associated with this VIN</small>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ justifyContent: "center", display: "flex" }}
        >
          <Button variant="outlined" color="primary" onClick={handlePinRequest}>
            Request Passcode
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* <form className={classes.formRoot} onSubmit={handleVerifyRequest}> */}
        <Grid item xs={12} md={8} style={{ marginTop: 10 }}>
          <Typography variant="h5" style={{ marginBottom: 10 }}>
            Step.2
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 10 }}>
            Enter your passcode {login?.email?(<Chip avatar={<Avatar><Email/></Avatar>} label= {login.email}  />):''}
          </Typography>
          <TextField
            inputRef={pinRef}
            label="Passcode"
            name="pin"
            value={login?.pin}
            type="text"
            variant="outlined"
            className={classes.flow}
            onChange={handleChange}
            required
            autoComplete="new-password"
            inputProps={{
              autoComplete: "off",
              form: {
                autoComplete: "off",
              },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ justifyContent: "center", display: "flex" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleVerifyRequest}
          >
            Login
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="default"
            onClick={reset}
          >
            Reset
          </Button>
        </Grid>
        {/* </form> */}
      </Grid>
    </div>
  );
}

import PointsCard from "../components/PointsCard";
import QRCard from "../components/QRCard";
import { Grid, Typography, Divider } from "@material-ui/core";
import { Build, Money } from "@material-ui/icons";
import { colors } from "../util/config";
import money from "../assets/savings.png";
import { app_config } from "../util/config";
// require("dotenv").config();

export default function About() {

  const REDEEM_POINT = process.env.REACT_APP_REDEEM_POINT || 50;
  const REWARD_POINT = process.env.REACT_APP_REWARD_POINT || 1000;

  return (
    <div className="main">
      {/* <PointsCard/> */}
      {/* <QRCard message="Scan at next service"/> */}

      <Grid
        container
        justifyContent="center"
        style={{ paddingTop: 40, textAlign: "center" }}
      >
        <Grid
          item
          xs={11}
          sm={6}
          md={3}
          style={{
            justifyContent: "center",
            display: "flex",
            fontSize: "0.8em",
          }}
        >
          <img style={{ width: "100%" }} src={money} />
        </Grid>
      </Grid>
      {/* <Grid item xs={10} md={8} style={{ textAlign: "center" }}>
          <div style={{ fontSize: "1.5em" }}>
            {" "}
            <span style={{ color: "#4599FE", fontWeight: "800" }}>BIMMER</span>
            <span style={{ color: "#15558A", fontWeight: "800" }}>LIFE</span>
            <span style={{ color: "#EE0405", fontWeight: "800" }}>STYLE</span>
          </div>
          <div
            style={{
              color: "#666",
              fontWeight: "400",
              letterSpacing: "1em",
              fontSize: "0.8em",
            }}
          >
            AUTOCARE
          </div>
        </Grid> */}

      <Grid
        container
        justifyContent="center"
        style={{ paddingTop: 40, textAlign: "center" }}
      >
        <Grid item md={8} xs={11} style={{ marginTop: 20, color:'#333'}}>
          <Typography style={{ color: colors.secondary }} variant="h5">
            Welcome to <b>{app_config.name}</b>
          </Typography>
        </Grid>
        <Grid item md={8} xs={11} style={{ marginTop: 20 }}>
          <Typography
            variant="body2"
            style={{ color: colors.secondary2, lineHeight: "1.8em" }}
          >
            As a member you will earn <b>1</b> point for every <b>{parseInt(REWARD_POINT)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}</b>{" "}
            JMD you spend with {app_config.name}. Your points can then
            be used to discount your next service, <b>10</b> points will give
            you a reward of <b>{parseInt(REDEEM_POINT*10)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}</b> JMD in savings.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

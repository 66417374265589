import { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { ProfileContext } from "../contexts/ProfileContext";
import Alert from "@material-ui/lab/Alert";
import { ServiceContext } from "../contexts/ServiceContext";
import {
  Grid,
  Typography,
  LinearProgress,
  Card,
  CardContent,
} from "@material-ui/core";
// import QRCard from "../components/QRCard";
import { CalendarToday, Speed } from "@material-ui/icons";
import { colors } from "../util/config";
import icon from "../assets/service.png";
var moment = require("moment");

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginTop: 15,
    background: "#fbfbfb",
    // maxWidth: 675,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function ServiceHistory() {
  // const { pdata, setPData } = useContext(ProfileContext);
  const { serviceHistory, loading } = useContext(ServiceContext);
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  useEffect(() => {
    console.log("loaded-serv", loading);
  }, [loading]);

  return serviceHistory[0] ? (
    <div className="main">
      <Grid container style={{ textAlign: "center", paddingTop: 12 }}>
        <Grid item xs={12}>
          <Typography style={{ color: colors.secondary }} variant="h5">
            <img src={icon} style={{ width: 32 }} />
            My <b>Service History</b>
          </Typography>
        </Grid>
      </Grid>

      <Grid container style={{ color: "#333", padding: 10 }} alignItems="left">
        <Grid item xs={12}>
          {serviceHistory?.map((i) => {
            console.log("mapped");
            return (
              <Card className={classes.root}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    <CalendarToday style={{ fontSize: "1.2em" }} />{" "}
                    {i.complete_at != "0000-00-00 00:00:00"
                      ? moment(i.complete_at).format("MMM DD, YYYY")
                      : "Service Date"}
                    <Speed style={{ fontSize: "1.2em", marginLeft: 20 }} />{" "}
                    {i.mileage?.toLocaleString()}
                  </Typography>

                  {i.service.length > 0
                    ? JSON.parse(i.service).map((s) => (
                        <Typography variant="body2" component="p">
                          {bull} {s}
                        </Typography>
                      ))
                    : ""}

                  {/* {bull} well meaning and kindly.
                  <br />
                  {'"a benevolent smile"'}
                </Typography> */}
                </CardContent>
              </Card>
            );
          })}
        </Grid>
      </Grid>
    </div>
  ) : (
    <>
      {!loading ? (
        <div className="main">
          <Alert severity="info">No Service History</Alert>
        </div>
      ) : (
        <LinearProgress style={{ marginTop: 68 }} color="secondary" />
      )}
    </>
  );
}

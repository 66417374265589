import React, {useContext} from 'react';
import './PointsCard.css'
import {Grid, LinearProgress} from '@material-ui/core';
import { Typography } from 'antd';
import {ProfileContext} from "../contexts/ProfileContext";

function PointsCard(){
    const REDEEM_POINT = process.env.REACT_APP_REDEEM_POINT || 50;
    const {pdata, setPData} = useContext(ProfileContext)
    return pdata?.first_name?(
        <>
        <div className="box">

            <Grid container>
                <Grid item xs={12}  style={{fontSize:'18pt'}}>
                    Hello {pdata?.first_name}
                </Grid> 
                  <Grid item xs={12} >
                <small>Available points</small>
                </Grid>                
                <Grid item xs={12} style={{display:"flex", justifyContent: "center"}}>
                   
                    <div className="points">
                        {pdata?.current_points}                        
                    </div>
                </Grid>
                <Grid item xs={12} style={{marginTop:20, fontSize:'12pt'}}>
                   Your points are worth {(pdata?.current_points * REDEEM_POINT)?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
                </Grid>
            </Grid>

        </div>

  
        </>
    ):<LinearProgress color="secondary" />
}
export default PointsCard;
// import React from 'react';
// import { Route, Redirect } from 'react-router-dom';
// import {checkAuth} from '../util';


// const PublicRoute = ({component: Component, restricted, ...rest}) => {
//     return (
//         // restricted = false meaning public route
//         // restricted = true meaning restricted route
//         <Route {...rest} render={props => (
//             checkAuth() && restricted ?
//                 <Redirect to="/" />
//             :  <React.Fragment><Component {...props} /></React.Fragment>
//         )} />
//     );
// };

// export default PublicRoute;


import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { checkAuth } from '../util/util';

const PublicRoute = () => {
    const auth = checkAuth(); // determine if authorized, from context or however you're doing it

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return !checkAuth() ? <Outlet /> : <Navigate to="/" />;
}
export default PublicRoute
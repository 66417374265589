import { createContext, useEffect, useState } from "react";
import "./App.css";
import BNav from "./components/BNav";
import Header from "./components/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import ServiceHistory from "./pages/ServiceHistory";

import { ProfileContext } from "./contexts/ProfileContext";
import { ServiceContext } from "./contexts/ServiceContext";
import About from "./pages/About";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { refreshCacheAndReload, getProfile, dataProvider_ } from "./util/util";
import PublicRoute from "./components/PublicRoute";
import Footer from "./components/Footer";

function App() {
  //set profile context
  // const ProfileContext = createContext();

  const [pdata, setPData] = useState({});
  const [loading, setLoading] = useState(true);
  const [serviceHistory, setServiceHistory] = useState({});

  useEffect(() => {
    refreshCacheAndReload();
    init();
    // getProfile.then((d) => {
    //  console.log(d)
    // });
    // console.log(getProfile())
    // getCloudProfile();
  }, []);

  const init = async () => {
    console.log('context')
    // let p = await getProfile();
    setLoading(true)
    try {
      const { data } = await dataProvider_("get", "/member", null);  
      const  service_history  = await dataProvider_("get", "/member/serviceHistory", null);  
      if(data?.data){
        setPData(data.data);
      }
 
      if(service_history?.data){
        setServiceHistory(service_history.data.data);
      }
      setLoading(false)
    } catch (error) {
      console.log(error, "init");
      setLoading(false)
    }
  
  };

  const theme = createTheme({
    typography: {
      fontFamily: [
        "Poppins",
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
  });

  // const [pdata, setPData] = useState("patrick.malcolm@gmail.com");
  // const pdata = "dsd"
  return (
    <Router>
      <ProfileContext.Provider value={{ pdata, setPData }}>
      <ServiceContext.Provider value={{ serviceHistory, setServiceHistory, loading }}>
        <ThemeProvider theme={theme}>
          <Header />

          <Routes>
            {/* <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/about" element={<About />} /> */}

<Route exact path="/login" element={<PublicRoute />}>
              <Route exact path="/login" element={<Login />} />
            </Route>


            <Route exact path="/about" element={<PrivateRoute />}>
              <Route exact path="/about" element={<><About /> <Footer/></>} />
            </Route>

            <Route exact path="/profile" element={<PrivateRoute />}>
              <Route exact path="/profile" element={<Profile />} />
            </Route>

            <Route exact path="/history" element={<PrivateRoute />}>
              <Route exact path="/history" element={<><ServiceHistory /> <Footer/></>} />
            </Route>

            <Route exact path="/" element={<PrivateRoute />}>
              <Route exact path="/" element={<><Home /> <Footer/></>} />
            </Route>
            <Route exact path="/home" element={<PrivateRoute />}>
              <Route exact path="/home" element={<><Home /> <Footer/></>} />
            </Route>
          </Routes>
        </ThemeProvider>
       
        <BNav />
      </ServiceContext.Provider>
      </ProfileContext.Provider>
    </Router>
  );
}

export default App;

import React from "react";
import { Grid, Typography, Divider } from "@material-ui/core";
import footer_logo from "../assets/bla-strokes.png";
import { Phone, Web } from "@material-ui/icons";
import { app_config } from "../util/config";
// import { getBuild } from "../util";
// import InstallPWA from "./InstallPWA";

export default function Footer() {
//   const [build, setBuild] = React.useState("");

//   React.useEffect(() => {
//     getBuild()
//       .then((res) => {
//         // console.log(res);
//         setBuild("Build: 2.0." + res.build);
//       })
//       .catch((e) => {
//         console.log(e);
//       });
//   },[build]);
  return (
    <Grid container style={{ marginTop: 50, marginBottom:65, padding: 20, textAlign:"center" }}>
      <Grid item xs={12}>
        {/* <div>
          <InstallPWA />
        </div> */}
        <Divider style={{ marginBottom: 6, background:"#eee" }} />
        {/* <div><img alt="footer" src={footer_logo} width={45} /></div> */}
    {/* <small style={{fontSize:'0.6em', color:'#16588E'}}><b>{app_config.name}</b> </small> */}
    <div><small style={{fontSize:'0.5em', color:'#696969'}}>{app_config.name} · 9 Church Hill Ave, Montego Bay · (876) 859 1704  </small></div>
        <div>
          <Typography
            variant="caption"
            style={{ color: "#b6b6b6", fontSize: "0.4em" }}
          >
            powered by{" "}
            <span
              style={{
                color: "#fff",
                background: "#454545",
                padding: "1px 2px 1px 2px",
              }}
            >
             <a href="https://malidaddy.com" target="_mali" style={{textDecoration:'none', color:'#fff'}}> <b>MALIDADDY</b> Ltd.</a>
            </span>
          </Typography>
        </div>
         </Grid>
    </Grid>
  );
}

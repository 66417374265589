import { useContext, useEffect } from "react";
import { ProfileContext } from "../contexts/ProfileContext";
import { Grid, Typography, LinearProgress } from "@material-ui/core";
import QRCard from "../components/QRCard";
import { Email, Phone } from "@material-ui/icons";
import {colors} from "../util/config";
export default function Profile() {
  const { pdata, setPData } = useContext(ProfileContext);

  // setProfile({
  //     user:"pj",
  //     vin:"1111"
  // })
  // console.log(value)
//   useEffect(() => {
// console.log(pdata)
//   }, [])
  return pdata?.first_name?(   
      <div className="main">        
        <Grid container style={{ textAlign: "center", color: "#333" }}>
          <Grid item xs={12}>
            <div
              style={{ padding: "30px 0px 30px 0px", background: "#f4f4f4" }}
            >
              {/* <QRCard /> */} 
              <Typography variant="h5">{pdata?.first_name+" "+pdata?.last_name}</Typography>
              <Typography variant="body2"> {pdata?.vin}</Typography>
            </div>
          </Grid>

         
        </Grid>

        <Grid container style={{ color: "#333", padding:10 }} alignItems="left">
        <Grid item xs={2}>
            <Phone style={{color:colors.secondary}} />
          </Grid>
          <Grid item xs={10}>
          <small>Mobile</small>
            <Typography variant="body1">{pdata?.contact}</Typography>        
          </Grid>

          <Grid item xs={2}>
            <Email  style={{color:colors.secondary}} />
          </Grid>
          <Grid item xs={10}>
          <small>Email</small>
            <Typography variant="body1"> {pdata?.email}</Typography>        
          </Grid>

        </Grid>
      </div>
  ):<LinearProgress style={{marginTop:68}} color="secondary" />

}

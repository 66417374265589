import PointsCard from "../components/PointsCard";
import QRCard from "../components/QRCard";
import { Grid, Typography, Divider } from "@material-ui/core";
import { Build, Loyalty } from "@material-ui/icons";
import { colors } from "../util/config";
import { Link } from "react-router-dom";
import { ProfileContext } from "../contexts/ProfileContext";
import { ServiceContext } from "../contexts/ServiceContext";
import { useContext, useEffect } from "react";
var moment = require("moment");


export default function Home() {
  const { pdata, setPData } = useContext(ProfileContext);
  const { serviceHistory, setServiceHistory } = useContext(ServiceContext);

  return (
    <div className="main">
      <PointsCard />
      {/* <QRCard message="Scan at next service"/> */}
      <Grid container style={{ marginTop: 20 }}>
        <Grid item xs={6}>
          <Grid container style={{ padding: 10 }}>
            <Grid item xs={12}>
              <small>Total points earned</small>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Loyalty style={{ fontSize: "24px", color: colors.secondary2 }} />
            </Grid>
            <Grid item xs={8} sm={10}>
              <Typography
                style={{ color: colors.secondary, fontWeight: 500 }}
                variant="body1"
              >
                {pdata?.total_points}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container style={{ padding: 10 }}>
            <Grid item xs={12}>
              <small>Your last service</small>
            </Grid>
            <Grid item xs={4} sm={2}>
            <Link to="/history"> <Build style={{ fontSize: "24px", color: colors.secondary2 }}/> </Link>
            </Grid>
            <Grid item xs={8} sm={10}>
              <Typography
                style={{ color: colors.secondary, fontWeight: 500 }}
                variant="body1"
              >
                {serviceHistory[0]
                  ? moment(serviceHistory[0].complete_at).format("MMM DD, YYYY")
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" style={{ marginTop: 20 }}>
      </Grid>
    </div>
  );
}

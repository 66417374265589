import React, { createContext } from "react";

export const ProfileContext = createContext("");

// function ProfileContext() {

//   return (
// <div>

// </div>
//   )
// }

// export default ProfileContext;

// npm install @mui/material
import React, { useState } from "react";
import { useNavigate, BrowserRouter as Router } from "react-router-dom";
import {
  BottomNavigationAction,
  BottomNavigation,
  Grid,
  Paper
} from "@material-ui/core";
import {
  Home,
  FormatListNumberedRtl,
  Info,
  LocalShipping,
  HomeOutlined,
  History,
  Person,
  InfoOutlined,
  Build,
} from "@material-ui/icons";
// import CropFreeIcon from '@material-ui/icons/CropFree';
// import { inSession } from './session';
// import { getJWTInfo } from "./session";
// import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";

function BNav() {
  const [navValue, setNavValue] = useState("");

  const BottomNavigationCBX = withStyles({
    root: {
      width: "100%",
      position: "fixed",
      bottom: 0,
    },
  })(BottomNavigation);

  const navigate = useNavigate();
  function handleClick() {
    navigate(1);
  }
  return (

    <Grid container>
        <Grid item xs={12}>
            <Paper elevation={3}>

        <BottomNavigationCBX
      defaultValue="home"
      showLabels
      value={navValue}
      onChange={(event, newValue) => {
        setNavValue(newValue);
        //   if (inSession() === true) {
        //     history.push("/" + newValue);
        //   } else {
        //     return false;
        //   }
        navigate("/" + newValue);
        // console.log("Btn", event);
        // console.log("Btn New value", newValue);
      }}

    //   style={{
    //       border:"1px solid #eee"
    //   }}
    >
      <BottomNavigationAction
        label="Home"
        value="home"
        icon={<HomeOutlined />}
      />
      {/* <BottomNavigationAction
        label="Recent Activity"
        value="activity"
        icon={<History />}
      /> */}
      <BottomNavigationAction
        label="History"
        value="history"
        icon={<Build />}
      />
      <BottomNavigationAction
        label="Profile"
        value="profile"
        icon={<Person />}
      />
      <BottomNavigationAction
        label="About"
        value="about"
        icon={<InfoOutlined />}
      />
    </BottomNavigationCBX>
    </Paper>
        </Grid>

    </Grid>
  
  );
}
export default BNav;
